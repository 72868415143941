import React from 'react'

export default function Silivri() {
    let ytChannel = "UCKsWMgobHraSg5234D5fUEA";
    return (
        <div className="live">
            <iframe className="liveframe" src={`https://www.youtube.com/embed/live_stream?channel=${ytChannel}&autoplay=true`} border="0"></iframe>
        </div>
    )
}

