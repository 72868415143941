import React from 'react'

export default function IBB() {
    let ytChannel = "UCyAn-CGmx_ecg2q0GrhLbcw";
    
    return (
        <div className="live">
            <iframe className="liveframe" src={`https://www.youtube.com/embed/live_stream?channel=${ytChannel}&autoplay=true`} border="0"></iframe>
        </div>
    )
}
