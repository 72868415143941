import React from 'react'
import { Link } from 'react-router-dom'

export default function Menu() {
    return (
        <div className="channels">
            <div className="channel"><Link to="/ibb">IBB</Link></div>
            <div className="channel"><Link to="/besiktas">Besiktas</Link></div>
            <div className="channel"><Link to="/kadikoy">Kadikoy</Link></div>
            <div className="channel"><Link to="/izmir">Izmir</Link></div>
            <div className="channel"><Link to="/samsun">Samsun</Link></div>
            <div className="channel"><Link to="/mugla">Mugla</Link></div>
            <div className="channel"><Link to="/pendik">Pendik</Link></div>
            <div className="channel"><Link to="/kartal">Kartal</Link></div>
            <div className="channel"><Link to="/silivri">Silivri</Link></div>
            <div className="channel"><Link to="/eyupsultan">EyupSultan</Link></div>
        </div>
    )
}
