import React from 'react'

export default function Besiktas() {
    let ytChannel = "UCFhHEYkXsiBT4CRlCX45Cpg";
    // return (
    //     <div className="live">
    //         <iframe className="liveframe" src={`https://www.youtube.com/embed/live_stream?channel=${ytChannel}&autoplay=true`} border="0"></iframe>
    //     </div>
    // )
    return (
        <div className="live">
            <iframe className="liveframe" src="http://player.vidpanel.com/?c=besiktas" border="0"></iframe>
        </div>
    )
}
