import React from 'react'

export default function Izmir() {
    let ytChannel = "UC1FsIvLFw-ntqAHWm8nEi5A";
    return (
        <div className="live">
            <iframe className="liveframe" src={`https://www.youtube.com/embed/live_stream?channel=${ytChannel}&autoplay=true`} border="0"></iframe>
        </div>
    )
}
