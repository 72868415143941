import React from 'react'

export default function Mugla() {
    let ytChannel = "UCSKUWNn9aMIwbFf5cvxoBPA"
    return (
        <div className="live">
            <iframe className="liveframe" src={`https://www.youtube.com/embed/live_stream?channel=${ytChannel}&autoplay=true`} border="0"></iframe>
        </div>
    )
}
