//import 'regenerator-runtime/runtime'

//React
import React, {useState, useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from "react-router-dom";


import Menu from './components/Menu'
import IBB from './components/channels/IBB'
import Besiktas from './components/channels/Besiktas'
import Kadikoy from './components/channels/Kadikoy'
import Izmir from './components/channels/Izmir'
import Samsun from './components/channels/Samsun'
import Mugla from './components/channels/Mugla'
import Pendik from './components/channels/Pendik'
import Kartal from './components/channels/Kartal'
import Silivri from './components/channels/Silivri'
import Eyupsultan from './components/channels/Eyupsultan';

const App = () => {
//    useTraceUpdate();
    return (
        <main>
        <BrowserRouter>
            <Menu/>
            <Switch>
            <Route path="/besiktas"><Besiktas/></Route>
            <Route path="/kadikoy"><Kadikoy/></Route>
            <Route path="/izmir"><Izmir/></Route>
            <Route path="/samsun"><Samsun/></Route>
            <Route path="/mugla"><Mugla/></Route>
            <Route path="/pendik"><Pendik/></Route>
            <Route path="/kartal"><Kartal/></Route>
            <Route path="/silivri"><Silivri/></Route>
            <Route path="/eyupsultan"><Eyupsultan/></Route>
            <Route path="/"><IBB/></Route>
            </Switch>
        </BrowserRouter>
        </main>
    )
}

ReactDOM.render(<App/>,document.getElementById("root"))